function getLanguage() {
  const language = localStorage.getItem('language');
  if (!language) {
    return 'spanish';
  }
  return language;
}

function setLanguage(language) {
  localStorage.setItem('language', language);
}

function selectElementsForLanguage(language) {
  let elements;
  if (language === 'spanish') {
    elements = document.querySelectorAll('[data-lang=es]');
  } else {
    elements = document.querySelectorAll('[data-lang=en]');
  }
  return elements
}

function changeVisibility(elements, visibility) {
  if (visibility === 'hidden') {
    elements.forEach((element) => {
      element.classList.add('hidden');
    });
  } else {
    elements.forEach((element) => {
      element.classList.remove('hidden');
    });
  }
}

function changeLanguage() {
  let language = localStorage.getItem('language');

  const currentElements = selectElementsForLanguage(language);
  changeVisibility(currentElements, 'hidden');

  if (language === 'spanish') {
    language = 'english';
  } else {
    language = 'spanish';
  }
  setLanguage(language);

  const newElements = selectElementsForLanguage(language);
  changeVisibility(newElements, 'visible');

}

const languageButton = document.querySelector('#internationalisation__button');
languageButton.addEventListener('click', changeLanguage)

const currentLanguage = getLanguage();
if (currentLanguage === 'english') {
  const currentElements = selectElementsForLanguage('spanish');
  changeVisibility(currentElements, 'hidden');
  const newElements = selectElementsForLanguage('english');
  changeVisibility(newElements, 'visible');
}

function openMenu(event) {
  const menu = document.querySelector('.header');
  menu.classList.add('header--show-menu');
}
function closeMenu(event) {
  const menu = document.querySelector('.header');
  menu.classList.remove('header--show-menu');
}

const menuButton = document.querySelector('.menu__button');
menuButton.addEventListener('click', openMenu);
const closeMenuButton = document.querySelector('.header__close-menu');
closeMenuButton.addEventListener('click', closeMenu)

console.log("This project was developed by manaranjoc and juancano21")
